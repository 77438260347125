import styled from "styled-components";
import {colors} from "./colors";

export const FlexWrap = styled.div<{
    $columns?: boolean,
    $alignItems?: string,
    $justifyContent?: string,
    $gap?: string,
    $width?: number,
    $height?: number
}>`
  display: flex;
  flex-direction: ${({$columns}) => $columns ? 'column' : 'row'};
  align-items: ${({$alignItems}) => $alignItems && $alignItems};
  justify-content: ${({$justifyContent}) => $justifyContent && $justifyContent};
  gap: ${({$gap}) => $gap && `${$gap}`};
  width: ${({$width}) => $width && `${$width}%`};
  height: ${({$height}) => $height && `${$height}%`};

  & > span {
    font-weight: 500;
  }
`

export const GridWrap = styled.div<{ $repeat: string, $center?: boolean, $gap?: number }>`
  display: grid;
  grid-template-columns: ${({$repeat}) => $repeat};
  align-items: ${({$center}) => $center && 'center'};
  justify-content: ${({$center}) => $center && 'center'};
  gap: ${({$gap}) => $gap && `${$gap}px`};
`

export const FormMultiWrap = styled.div`
  display: flex;
  gap: 50px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  opacity: 0;
  transform: translateX(50px);
  animation: 0.4s slideX ease-in-out 0.3s;
  animation-fill-mode: forwards;

  & > h1 {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e7eb;
  }

  @media screen and (max-width: 768px) {
    min-width: calc(100vw - 80px) !important;
  }

  & > div:last-child {
    border-bottom: 1px solid #e5e7eb;
  }

  & > div {
    width: 100%;
    display: flex;

    & > span:first-child {
      border-left: 1px solid #e5e7eb;
    }

    & > span {
      width: 150px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      background-color: #F1F5F9FF;
      border-right: 1px solid #e5e7eb;
      border-top: 1px solid #e5e7eb;
    }

    & > span.require:after {
      content: '*';
      padding-left: 5px;
      color: ${colors.red["050"]}
    }

    & > div {
      flex: 1;
      padding: 10px 20px;
      min-height: 60px;
      border-right: 1px solid #e5e7eb;
      border-top: 1px solid #e5e7eb;
      border-collapse: collapse;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & label,
    & .react-datepicker-wrapper,
    & select,
    & input {
      max-width: 250px;
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      & > span {
        width: 120px;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      
      & > span {
        padding: 10px;
        width: 100%;
      }

      & > div {
        flex: 1;
        gap: 5px;
        width: auto;
        padding: 10px;
        border-left: 1px solid #e5e7eb;

        & label,
        & .react-datepicker-wrapper,
        & select,
        & input {
          max-width: 100%;
        }
      }
    }
  }

  &:nth-child(1) {
    z-index: 10
  }

  &:nth-child(2) {
    z-index: 9;
  }

  &:nth-child(3) {
    z-index: 8;
  }

  &:nth-child(4) {
    z-index: 7;
  }

  &:nth-child(5) {
    z-index: 6;
  }

`

export const Hr = styled.hr<{ $width?: string, $height?: string, $vertical?: boolean }>`
  ${({$vertical, $height, $width}) => $vertical ? `
    width: 1px;
    height: ${$height ? $height : '100%'}; 
  ` : `
    height: 1px;
    width: ${$width ? $width : '100%'};
  `}

  background-color: #eee;
  border: 0;
`

export const Marquee = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  cursor: pointer;

  &:hover {
    animation-play-state: paused;
  }

  @keyframes marquee {
    0% {
      text-indent: 100%;
    }
    100% {
      text-indent: -100%;
    }
  }
`

export const Spinner1 = styled.div`
  margin: 20px auto;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: relative;
  border-top: 2.5px solid #fff;
  border-right: 2.5px solid #fff;
  border-bottom: 2.5px solid #fff;
  border-left: 2.5px solid rgba(0, 0, 0, 0.3);
  transform: translateZ(0);
  animation: spinner1 1.1s infinite linear;

  @keyframes spinner1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner2 = styled.div`
  border-radius: 50%;
  width: 19px;
  height: 19px;
  position: relative;
  border-top: 2.5px solid transparent;
  border-right: 2.5px solid #fff;
  border-bottom: 2.5px solid #fff;
  border-left: 2.5px solid #fff;
  transform: translateZ(0);
  animation: spinner1 1.1s infinite linear;

  @keyframes spinner1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner3 = styled.div`
  color: #fff;
  margin: 0 auto;
  border-radius: 50%;
  top: -24px;
  width: 10px;
  height: 10px;
  position: relative;
  transform: translateZ(0);
  animation: spinner3 1.8s infinite ease-in-out;
  animation-delay: -0.16s;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -15px;
    animation: spinner3 1.8s infinite ease-in-out;
    animation-delay: -0.32s;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 15px;
    animation: spinner3 1.8s infinite ease-in-out;
  }

  @keyframes spinner3 {
    0%, 80%, 100% {
      box-shadow: 0 24px 0 -12px;
    }
    40% {
      box-shadow: 0 24px 0 0;
    }
  }
`

export const Danger = styled.small`
  padding-top: 5px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.red["050"]};

  opacity: 0;
  transform: translateY(-10px);
  animation: 0.4s slideY ease-in-out 0.3s;
  animation-fill-mode: forwards;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
`


import styled from 'styled-components';
import {ReactNode, useCallback, useEffect, useRef, MouseEvent} from "react";
import {useStoreModal, useStoreModalValue} from "../../../store/modal";

const Modal = ({title, children}: { title?: string, children?: ReactNode }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const {show, title: stateTitle, maxWidth: stateMaxWidth, children: stateChildren} = useStoreModalValue();
    const {closeModal} = useStoreModal();

    useEffect(() => {
        const el = document.querySelector('body');
        if (el) el.style.overflow = show ? 'hidden' : '';
    }, [show]);

    const onModalClose = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (modalRef.current === e.target) closeModal();
    }, []);

    if (!show) return (<></>);
    if (show && (!children && !stateChildren)) return (<></>);

    return (
        <ModalWrap onClick={(e) => onModalClose(e)} ref={modalRef}>
            <ModalInnerWrap $maxWidth={stateMaxWidth}>
                <ModalHeaderWrap>
                    <h2>{title && title}</h2>
                    <h2>{stateTitle && stateTitle}</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                         onClick={() => closeModal()}
                    >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                    </svg>
                </ModalHeaderWrap>

                <ModalContentWrap>
                    {stateChildren && stateChildren}
                    {children && children}
                </ModalContentWrap>
            </ModalInnerWrap>
        </ModalWrap>
    );
}

const ModalWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 40px;
  z-index: 5;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

const ModalInnerWrap = styled.div<{ $maxWidth?: string }>`
  width: 100%;
  max-width: ${({$maxWidth}) => $maxWidth ? $maxWidth : '900px'};
  max-height: 80vh;
  background-color: #fff;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;

  position: relative;
`

const ModalHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`

const ModalContentWrap = styled.div`
  width: 100%;
  overflow: auto;
`

export default Modal;

import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";
import {alertLevels, alertTypes} from "../types/alert";

type actionsTypes = {
    actions: {
        showAlert: ({title, level, onSubmit}: Pick<alertTypes, 'title' | 'level' | 'onSubmit'>) => void,
        closeAlert: () => void,
    }
}

const useStoreAlertCreate = create<alertTypes & actionsTypes>((set) => ({
    show: false,
    actions: {
        showAlert: ({title, level = alertLevels.normal, onSubmit}) => set((state) => ({...state, show: true, title, level, onSubmit})),
        closeAlert: () => set((state) => ({...state, show: false})),
    },
}));

export const useStoreAlert = () => useStoreAlertCreate(useShallow((state) => state.actions));
export const useStoreAlertValue = () => useStoreAlertCreate(useShallow((state) => state));

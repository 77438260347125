import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";
import {modalTypes} from "../types/modal";

type actionsTypes = {
    actions: {
        showModal: ({title, maxWidth, children}: Pick<modalTypes, 'children' | 'title' | 'maxWidth'>) => void,
        closeModal: () => void,
    }
}

const useStoreModalCreate = create<modalTypes & actionsTypes>((set) => ({
    show: false,
    actions: {
        showModal: ({title, maxWidth, children}) => set((state) => ({...state, show: true, title, maxWidth, children})),
        closeModal: () => set((state) => ({...state, show: false, title: '', maxWidth: '', children: ''})),
    },
}));

export const useStoreModal = () => useStoreModalCreate(useShallow((state) => state.actions));
export const useStoreModalValue = () => useStoreModalCreate(useShallow((state) => state));

import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {lazy, Suspense, useEffect} from "react";
import {useLoginCheck} from "../../api/user";
import LayoutLeftTemplate from "../../components/templates/layout/left";
import {StaticCategory} from "../../constants/staticCategory";
import {useStoreUser, useStoreUserValue} from "../../store/user";

const Login = lazy(() => import('../login').then((module) => ({default: module.Login})));
const AddUser = lazy(() => import('../user/add').then((module) => ({default: module.AddUser})));
const User = lazy(() => import('../user').then((module) => ({default: module.User})));
const EditUser = lazy(() => import('../user/edit').then((module) => ({default: module.EditUser})));
const DetailUser = lazy(() => import('../user/detail').then((module) => ({default: module.DetailUser})));

const AddMember = lazy(() => import('../member/add').then((module) => ({default: module.AddMember})));
const AddMemberOrder = lazy(() => import('../member/order').then((module) => ({default: module.AddMemberOrder})));
const Member = lazy(() => import('../member').then((module) => ({default: module.Member})));
const EditMember = lazy(() => import('../member/edit').then((module) => ({default: module.EditMember})));
const DetailMember = lazy(() => import('../member/detail').then((module) => ({default: module.DetailMember})));
const PaymentMember = lazy(() => import('../member/payment').then((module) => ({default: module.PaymentMember})));

const Order = lazy(() => import('../order').then((module) => ({default: module.Order})));
const EditOrder = lazy(() => import('../order/edit').then((module) => ({default: module.EditOrder})));

const Sale = lazy(() => import('../sale').then((module) => ({default: module.Sale})));
const DetailSale = lazy(() => import('../sale/detail').then((module) => ({default: module.DetailSale})));

const Pay = lazy(() => import('../pay').then((module) => ({default: module.Pay})));
const PayError = lazy(() => import('../pay/error').then((module) => ({default: module.PayError})));
const PayComplete = lazy(() => import('../pay/complete').then((module) => ({default: module.PayComplete})));

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {setUser} = useStoreUser();
    const {mutate} = useLoginCheck();
    const {role} = useStoreUserValue();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/login' || location.pathname.indexOf('/pay') !== -1) return;
        if (location.pathname === '/') navigate(role === 'Admin' ? '/user' : '/member');

        mutate(undefined, {
            onSuccess: async ({data}) => {
                if (!data.seq && location.pathname !== '/login') {
                    navigate('/login');
                    return;
                }

                setUser({seq: data.seq, id: data.id, email: data.email, name: data.name, role: data.role});

                StaticCategory.map(i => {
                    if (i.route.indexOf(location.pathname) !== -1 && i.role.indexOf(data.role || '') === -1) navigate(data.role === 'Admin' ? '/user' : '/member');
                });
            },
        });
    }, [location.pathname]);

    return (
        <Suspense fallback={location.pathname === '/login' ? <></> : <LayoutLeftTemplate children={<></>}/>}>
            <Routes>
                <Route path="/login" element={<Login/>}/>

                <Route path="/user" element={<User/>}/>
                <Route path="/user/:seq" element={<DetailUser/>}/>
                <Route path="/user/add" element={<AddUser/>}/>
                <Route path="/user/edit/:seq" element={<EditUser/>}/>

                <Route path="/member" element={<Member/>}/>
                <Route path="/member/:seq" element={<DetailMember/>}/>
                <Route path="/member/add" element={<AddMember/>}/>
                <Route path="/member/add/order/:seq" element={<AddMemberOrder/>}/>
                <Route path="/member/edit/:seq" element={<EditMember/>}/>
                <Route path="/member/payment/:seq" element={<PaymentMember/>}/>

                {/*<Route path="/order" element={<Order/>}/>*/}
                {/*<Route path="/order/edit/:seq" element={<EditOrder/>}/>*/}

                <Route path="/sale" element={<Sale/>}/>
                <Route path="/sale/:seq" element={<DetailSale/>}/>

                <Route path="/pay" element={<Pay/>}/>
                <Route path="/pay/error" element={<PayError/>}/>
                <Route path="/pay/complete" element={<PayComplete/>}/>
            </Routes>
        </Suspense>
    );
}

import {create} from "zustand";
import {useShallow} from "zustand/react/shallow";
import {userTypes} from "../types/user";

type actionsTypes = {
    actions: {
        setUser: ({seq, id, email, name, role}: Pick<userTypes, 'seq' | 'id' | 'email' | 'name' | 'role'>) => void,
        deleteUser: () => void,
    }
}

const useStoreUserCreate = create<userTypes & actionsTypes>((set) => ({
    seq: '',
    id: '',
    email: '',
    name: '',
    role: '',
    actions: {
        setUser: ({seq, id, email, name, role}) => set((state) => ({...state, seq, id, email, name, role})),
        deleteUser: () => set((state) => ({...state, seq: '', id: '', email: '', name: '', role: ''})),
    },
}));

export const useStoreUser = () => useStoreUserCreate(useShallow((state) => state.actions));
export const useStoreUserValue = () => useStoreUserCreate(useShallow((state) => state));

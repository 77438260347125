import {create} from "zustand";
import {layoutTypes} from "../types/layout";
import {useShallow} from "zustand/react/shallow";

type actionsTypes = {
    actions: {
        setFold: () => void
    }
}

const useStoreLayoutCreate = create<layoutTypes & actionsTypes>((set) => ({
    fold: false,
    actions: {
        setFold: () => set((state) => ({...state, fold: !state.fold}))
    },
}));

export const useStoreLayout = () => useStoreLayoutCreate(useShallow((state) => state.actions));
export const useStoreLayoutValue = () => useStoreLayoutCreate(useShallow((state) => state));
